import React, { useState, useEffect } from 'react';
import SubmitButtonNext from './SubmitButtonNext';
import logo from './imgs/edana.png';
import TextareaAutosize from 'react-textarea-autosize';

const TechnologyList = ({ handleNextStep, setSelectedTechnologyId, selectedTechnologyId, setClientName, setProjectName, clientName, projectName, setHourlyRate, hourlyRate, setSelectedOptionsByStep, setTotalHours}) => {
  const [technologyTypes, setTechnologyTypes] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleTechnologySelect = (technology) => {
    setSelectedTechnologyId(technology.id);
    setSelectedTechnology(technology);
    setSelectedOptionsByStep({});
    setTotalHours(0);
  };

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleNextButtonClick = () => {
      if (
        clientName.trim() === '' ||
        projectName.trim() === ''
         ) {
          setErrorMessage('Veuillez remplir tous les champs.');
      } else {
        if(hourlyRate>0){
          handleNextStep();
        }else{
          setErrorMessage('Entrez un nombre dans le tarif horaire.');
        }
      }
  };

  const apiUrl = process.env.REACT_APP_API_URL;
//API call
  useEffect(() => {
    const fetchTechnologyTypes = async () => {
      try {
        const response = await fetch(`${apiUrl}/technology-types`);
        const data = await response.json();
        setTechnologyTypes(data);
      } catch (error) {
        setError('Erreur lors de la récupération des fonctionnalités');
      } finally {
        setLoading(false);
      }
    };

    fetchTechnologyTypes();
  }, []);

  return (
    <div className="block-centered">
      <center><img src={logo} className="logo" /></center>

      <div>
        <h2>Client :</h2>
        <input
        className="form-control"
          type="text"
          id="clientName"
          value={clientName}
          onChange={handleClientNameChange}
        />
      </div>

      <div>
        <h2>Projet&nbsp;:</h2>
        <TextareaAutosize
          className="form-control"
          type="text"
          id="projectName"
          value={projectName}
          onChange={handleProjectNameChange}
        />
      </div>

      <div>
        <h2>Tarif horaire&nbsp;:</h2>
        <input
          className="form-control"
          pattern="[0-9]*"
          inputMode="numeric"
          type="number"
          id="hourlyRate"
          value={hourlyRate}
          onChange={(e) => setHourlyRate(e.target.value)}
        />
      </div>

      <h2>Quelle technologie souhaitez-vous utiliser&nbsp;?</h2>

      {loading ? (
        <p>Chargement en cours...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="btn-group-common-border">
          {technologyTypes.map((technology) => (
            <label
              key={technology.id}
              className={`btn btn-light-square ${selectedTechnology === technology ? 'active' : ''}`}
            >
              <input
                type="radio"
                name="technologyType"
                id={`technologyType-${technology.id}`}
                autoComplete="off"
                checked={selectedTechnologyId === technology.id}
                onChange={() => handleTechnologySelect(technology)}
              />{' '}
              {technology.name}
            </label>
          ))}
        </div>
      )}

      {(selectedTechnology || selectedTechnologyId) && (
        <>
          <div>
          {errorMessage && <p style={{ color: 'red', textAlign: 'right' }}>{errorMessage}</p>}
            <div className="button-block notopborder">
              <SubmitButtonNext onClick={handleNextButtonClick} label="Étape suivante" />
            </div>
          </div>
        </>
      )}

      <div className="copyright">Chiffrage v.1.0 / MBJ Solutions Sàrl</div>

    </div>
  );
};

export default TechnologyList;
