import React, { useRef, useEffect } from 'react';
import arrowLeftIcon from './imgs/arrow-left.png';

const SubmitButtonPrev = ({ onClick, label }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const textWidth = textRef.current.offsetWidth -13;
    const underline = textRef.current.nextElementSibling;
    const textLeft = textRef.current.offsetLeft +13;

    underline.style.width = `${textWidth}px`;
    underline.style.left = `${textLeft}px`;
  }, [label]);

  return (
<button className="btn btn-light-square btn-primary btn-container-prev btn-prev" onClick={onClick}>
<span className="btn-text-prev" ref={textRef}>
<img src={arrowLeftIcon} className="arrow-left-icon" /> {label}
</span>
<span className="btn-underline-prev"></span>
</button>
  );
};

export default SubmitButtonPrev;