import React, { useRef, useEffect } from 'react';

const SubmitButtonNext = ({ onClick, label }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const textWidth = textRef.current.offsetWidth;
    const underline = textRef.current.nextElementSibling;
    const textLeft = textRef.current.offsetLeft;

    underline.style.width = `${textWidth}px`;
    underline.style.left = `${textLeft}px`;
  }, [label]);

  return (
    <button className="btn btn-light-square btn-primary btn-container btn-next" onClick={onClick}>
      <span className="btn-text" ref={textRef}>
        {label}
      </span>
      <span className="btn-underline"></span>
    </button>
  );
};

export default SubmitButtonNext;