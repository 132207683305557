import React, { useState, useEffect } from 'react';
import SubmitButtonPrev from './SubmitButtonPrev';
import SubmitButtonNext from './SubmitButtonNext';
import logo from './imgs/edana.png';

const SummaryPage = ({ selectedOptionsByStep, totalHours, hourlyRate, handlePrevStep, clientName, projectName, selectedTechnologyId }) => {
  const [optionsDetails, setOptionsDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);

  const APIMapping = {
    functionalities: "functionality",
    workflows: "workflow",
    integrations: "integration",
    notifications: "notification"
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  //API call
  useEffect(() => {
    const fetchOptionsDetails = async () => {
      try {
        setLoading(true);

        const promises = Object.keys(selectedOptionsByStep).map(async (type) => {
          const details = await Promise.all(selectedOptionsByStep[type].map(async (optionId) => {
            const response = await fetch(`${apiUrl}/${APIMapping[type] || type}/${selectedTechnologyId}/${optionId}`);
            const data = await response.json();
            return { id: optionId, name: data.name, hours: data.hours };
          }));
          return { [type]: details };
        });

        const optionsDetailsObject = Object.assign({}, ...await Promise.all(promises));
        setOptionsDetails(optionsDetailsObject);
      } catch (error) {
        console.error('Erreur lors de la récupération des détails des options', error);
        setError('Erreur lors de la récupération des détails des options');
      } finally {
        setLoading(false);
      }
    };

    fetchOptionsDetails();
  }, [selectedOptionsByStep, selectedTechnologyId]);

  const typeTextMapping = {
    functionalities: "Fonctionnalités",
    workflows: "Workflows",
    integrations: "Intégrations",
    notifications: "Notifications"
  };

  const renderSelectedOptions = () => {
    return Object.keys(optionsDetails).map((type) => (
      <div key={type}>
        <span className='resume-options'>{typeTextMapping[type] || type}</span>
        <ul>
          {optionsDetails[type].map((optionDetail) => (
            <li key={Object.keys(optionDetail)[0]}>
              {optionDetail.name}
              <div className='totalitem'>{optionDetail.hours} heures = {(
                optionDetail.hours * hourlyRate
              ).toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'CHF',
              })}</div>
            </li>
          ))}
        </ul>
      </div>
    ));
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${apiUrl}/estimation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client: clientName,
          project: projectName,
          technology_type_id: selectedTechnologyId,
          options: optionsDetails,
        }),
      });

      if (response.ok) {
        setSaveStatus('success');
        console.log('Données enregistrées avec succès !');
      } else {
        setSaveStatus('error');
        console.error('Échec de l\'enregistrement des données');
      }
    } catch (error) {
      setSaveStatus('error');
      console.error('Erreur lors de la requête d\'enregistrement des données', error);
    }
  };

  const renderSaveButton = () => {
    if (saveStatus === 'success') {
      return <p className="success-message">Succès&nbsp;!</p>;
    } else if (saveStatus === 'error') {
      return <p className="error-message">Échec.</p>;
    } else {
      return (
        <SubmitButtonNext onClick={handleSave} label="Enregistrer" />
      );
    }
  };

  return (
    <div className="block-centered">
      <center><img src={logo} className="logo" /></center>

      <h2>Récapitulatif du chiffrage&nbsp;:</h2>

      {loading ? (
        <p>Chargement en cours...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        renderSelectedOptions()
      )}

      <div className="sum">
        <h2>Total du projet</h2>
        <p>{totalHours} heures = {(
          totalHours * hourlyRate
        ).toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'CHF',
        })}</p>
      </div>

      <div className="button-block notopborder margintop30">
        <div className='halfleft'>
          <SubmitButtonPrev onClick={handlePrevStep} label="Étape précédente" />
        </div>

        <div className='halfright'>
        {renderSaveButton()}
        </div>
      </div>

      <div className="copyright">Chiffrage v.1.0 / MBJ Solutions Sàrl</div>
    </div>
  );
};

export default SummaryPage;
