import React, { useState } from 'react';
import Stepper from './Stepper';
import TechnologyList from './TechnologyList';
import SelectOptions from './SelectOptions';
import SummaryPage from './SummaryPage';

const App = () => {

  return (
    <Stepper>

      <TechnologyList />

      <SelectOptions type="functionalities" />

      <SelectOptions type="workflows" />

      <SelectOptions type="integrations" />

      <SelectOptions type="notifications" />

      <SummaryPage />

    </Stepper>
  );
};

export default App;
