import React, { useState, useEffect } from 'react';
import SubmitButtonNext from './SubmitButtonNext';
import SubmitButtonPrev from './SubmitButtonPrev';
import logo from './imgs/edana.png';

const SelectOptions = ({ handleNextStep, handlePrevStep, type, selectedTechnologyId, selectedOptionsByStep, setSelectedOptionsByStep, totalHours, setTotalHours, hourlyRate }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // API call
    const fetchOptions = async () => {
      try {
        const response = await fetch(`${apiUrl}/technology-types/${selectedTechnologyId}/${type}`);
        const data = await response.json();
        setOptions(data);
      } catch (error) {
        setError(`Erreur lors de la récupération des ${type}`);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [selectedTechnologyId, type]);

  const handleOptionSelect = (optionId, hours) => {
    setSelectedOptionsByStep((prevSelectedOptions) => {
      const updatedOptions = {
        ...prevSelectedOptions,
        [type]: Array.from(new Set([...(prevSelectedOptions[type] || []), optionId])),
      };
      return updatedOptions;
    });

    setTotalHours((prevTotalHours) => prevTotalHours + hours);
  };

  const handleOptionDeselect = (optionId, hours) => {
    setSelectedOptionsByStep((prevSelectedOptions) => {
      const updatedOptions = {
        ...prevSelectedOptions,
        [type]: (prevSelectedOptions[type] || []).filter((id) => id !== optionId),
      };
      return updatedOptions;
    });

    setTotalHours((prevTotalHours) => prevTotalHours - hours);
  };

  const handlePrevButtonClick = () => {
    handlePrevStep();
  };

  const handleNextButtonClick = () => {
    handleNextStep();
  };

  const typeTextMapping = {
    functionalities: "fonctionnalités souhaitées",
    workflows: "workflows souhaités",
    integrations: "intégrations souhaitées",
    notifications: "notifications souhaitées"
  };

  return (
    <div className="block-centered">
      <center><img src={logo} className="logo" /></center>

      <h2>Choisissez les {typeTextMapping[type] || type}&nbsp;:</h2>

      {loading ? (
        <p>Chargement en cours...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="checkbox-group">
          {options.map((option) => (
            <label key={option.id} className="checkbox-label">
              <input
                type="checkbox"
                id={`${type}-${option.id}`}
                checked={(selectedOptionsByStep[type] || []).includes(option.id)}
                onChange={() =>
                  (selectedOptionsByStep[type] || []).includes(option.id)
                    ? handleOptionDeselect(option.id, option.hours)
                    : handleOptionSelect(option.id, option.hours)
                }
              />{' '}
              {option.name}
            </label>
          ))}
        </div>
      )}

      {totalHours > 0 && (
        <>
          <div>
            <div className="button-block">
              
              <div className='halfleft'>
                <SubmitButtonPrev onClick={handlePrevButtonClick} label="Étape précédente" />
              </div>

              <div className='halfright'>
                <SubmitButtonNext onClick={handleNextButtonClick} label="Étape suivante" />
              </div>

            </div>
          </div>
        </>
      )}

      <div className="sum">
        <h2>Total du projet</h2>
        <p>{totalHours} heures =  {(
      totalHours*hourlyRate
    ).toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'CHF',
    })}</p>
      </div>

      <div className="copyright">Chiffrage v.1.0 / MBJ Solutions Sàrl</div>

    </div>
  );
};

export default SelectOptions;
