import React, { useState } from 'react';

const Stepper = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTechnologyId, setSelectedTechnologyId] = useState(null);
  const [clientName, setClientName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [totalHours, setTotalHours] = useState(0);
  const [selectedOptionsByStep, setSelectedOptionsByStep] = useState({});

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <div>
      {React.cloneElement(children[currentStep], {
        handleNextStep,
        handlePrevStep,
        selectedTechnologyId,
        setSelectedTechnologyId,
        clientName,
        setClientName,
        projectName,
        setProjectName,
        hourlyRate,
        setHourlyRate,
        totalHours,
        setTotalHours,
        selectedOptionsByStep,
        setSelectedOptionsByStep
      })}
    </div>
  );
};

export default Stepper;
